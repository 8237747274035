import React from 'react';

interface ProfileInfoButtonsProps {
  onClickFirstButton: () => void;
  onClickSecondButton: () => void;
}

const ProfileInfoButtons: React.FC<ProfileInfoButtonsProps> = ({ onClickFirstButton, onClickSecondButton }) => {
  return (
    <div className="profile-info-buttons">
      <div className="sell-button"  onClick={onClickSecondButton}>Collected Card<span id="seeMyCards"></span></div>
      <div className="buy-button" onClick={onClickFirstButton}>Buy Card<span id="buyMyCards"></span></div>
    </div>
  );
};


export default ProfileInfoButtons;